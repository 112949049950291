import { useState, useRef } from "react"
import styled from "styled-components"
import useInterval from "react-useinterval"
import Sketch from "react-p5"
import p5Types from "p5"
import { useCanvasSize } from "./useCanvasSize"
import Controls, { Control } from "./Controls"
import "./main.css"

interface DropletsProps {
  x: number
  y: number
  w: number
  s: number
}

const FPS = 60
const TOUCH_ELLIPSE_MODIFIER = 0.75
const MOBILE_DROP_MULTIPLIER = 1.5
const MOBILE_SIZE_LIMIT = 400

function App() {
  const urlParams = new URLSearchParams(window.location.search)
  const filename = (urlParams.get("f") ?? `0`) + `.jpeg`

  const canvasSize = useCanvasSize()
  const [ellipseSize, setEllipseSize] = useState(100)
  const [dropletInterval, setDropletInterval] = useState(0.1)
  const [dropletChance, setDropletChance] = useState(1)
  const [dropSpeed, setDropSpeed] = useState(0.3)
  const [minDropletSize, setMinDropletSize] = useState(5)
  const [maxDropletSize, setMaxDropletSize] = useState(10)
  const [init, setInit] = useState(false)

  return !canvasSize ? null : (
    <>
      <Title>
        <h1>MRKTBLMS Demo #00{filename.replace(".jpeg", "")}</h1>
      </Title>
      <Footer>
        <h2>
          <a href="https://wvgg.co/mrktblms" target="_blank">
            About
          </a>
        </h2>
        <ul>
          <li>
            <a href={`/?f=0`}>0</a>
          </li>
          <li>
            <a href={`/?f=1`}>1</a>
          </li>
          <li>
            <a href={`/?f=2`}>2</a>
          </li>
          <li>
            <a href={`/?f=3`}>3</a>
          </li>
          <li>
            <a href={`/?f=4`}>4</a>
          </li>
        </ul>
      </Footer>
      <Wrapper {...{ canvasSize, filename }} className={init ? "active" : ""}>
        <Image src={`/images/${filename}`} {...{ canvasSize }} />
        {canvasSize !== null && (
          <Animation
            {...{
              ellipseSize,
              dropletChance,
              dropSpeed,
              minDropletSize,
              maxDropletSize,
              canvasSize,
              dropletInterval,
              setInit,
            }}
          />
        )}
      </Wrapper>
      <Controls>
        <Control value={ellipseSize} setter={setEllipseSize} min={10} max={150} step={1} label="wipey size" />

        <Control value={minDropletSize} setter={setMinDropletSize} min={1} max={maxDropletSize} step={1} label="min droplet size" />
        <Control value={maxDropletSize} setter={setMaxDropletSize} min={minDropletSize} max={50} step={1} label="+ max droplet size" />

        <Control value={dropletInterval} setter={setDropletInterval} min={0.1} max={10} step={0.1} label="interval" />
        <Control value={dropletChance} setter={setDropletChance} min={1} max={10} step={1} label="chance" />
        <Control value={dropSpeed} setter={setDropSpeed} min={0.1} max={5} step={0.1} label="max drop speed" />
        <P>
          Every {dropletInterval} seconds there is a {dropletChance} in 10 chance that a droplet will appear
        </P>
        <P>
          Droplets are between {minDropletSize} and {Math.floor(minDropletSize + maxDropletSize)} pixels in size. They randomly move up to {dropSpeed * 60} pixels per second
        </P>
      </Controls>
    </>
  )
}

function Animation({
  ellipseSize,
  dropletChance,
  dropSpeed,
  minDropletSize,
  maxDropletSize,
  canvasSize,
  dropletInterval,
  setInit,
}: {
  ellipseSize: number
  dropletChance: number
  dropSpeed: number
  minDropletSize: number
  maxDropletSize: number
  canvasSize: number
  dropletInterval: number
  setInit: (b: boolean) => void
}) {
  const urlParams = new URLSearchParams(window.location.search)
  const filename = (urlParams.get("f") ?? `0`) + `.jpeg`
  const blurAmount = parseInt(urlParams.get("blur") ?? "15")

  const [image, setImage] = useState<p5Types.Graphics>()

  const droplets = useRef<Array<DropletsProps>>([])

  useInterval(() => {
    if (Math.random() > dropletChance * 0.1) return
    const dropletModifier = canvasSize < MOBILE_SIZE_LIMIT ? MOBILE_DROP_MULTIPLIER : 1
    droplets.current.push({
      x: Math.random() * canvasSize,
      y: 0,
      w: Math.random() * (maxDropletSize + minDropletSize) * dropletModifier,
      s: Math.random() * dropSpeed * MOBILE_DROP_MULTIPLIER,
    })
  }, dropletInterval * 1000)

  const preload = (p5: p5Types) => {
    p5.loadImage(`/images/${filename}`, (bitmap) => {
      const graphic = p5.createGraphics(canvasSize, canvasSize)
      graphic.image(bitmap, 0, 0, canvasSize, canvasSize)
      graphic.filter(p5.BLUR, blurAmount)
      graphic.loadPixels()
      setImage(graphic)
    })
  }

  const setup = (p5: p5Types, canvasParentRef: Element) => {
    p5.createCanvas(canvasSize, canvasSize).parent(canvasParentRef)
    p5.frameRate(FPS)
    p5.image(image!, 0, 0, canvasSize, canvasSize)
    setInit(true)
  }

  const draw = (p5: p5Types) => {
    p5.loadPixels()
    for (let i = 3; i < p5.pixels.length; i += 4) {
      p5.pixels[i - 1] = image!.pixels[i - 1]
      p5.pixels[i - 2] = image!.pixels[i - 2]
      p5.pixels[i - 3] = image!.pixels[i - 3]
      p5.pixels[i] = p5.pixels[i] + 1
    }
    p5.updatePixels()
    droplets.current = droplets.current.reduce((acc, d) => {
      if (d.y > canvasSize) return acc
      const updated = {
        ...d,
        y: d.y + d.s,
      }
      p5.erase(255, 0)
      p5.ellipse(d.x, d.y, d.w)
      p5.noErase()
      acc.push(updated)
      return acc
    }, [] as Array<DropletsProps>)
  }

  const mouseMoved = (p5: p5Types) => {
    p5.erase(255, 0)
    p5.ellipse(p5.mouseX, p5.mouseY, ellipseSize)
    p5.noErase()
  }

  const touchMoved = (p5: p5Types) => {
    p5.erase(255, 0)
    p5.ellipse(p5.mouseX, p5.mouseY, ellipseSize * TOUCH_ELLIPSE_MODIFIER)
    p5.noErase()
  }

  return (
    <>
      {/* @ts-ignore */}
      <Sketch {...{ preload, setup, draw, mouseMoved, touchMoved }} />
    </>
  )
}

const Wrapper = styled.main<{
  canvasSize: number
  filename: string
}>`
  position: absolute;
  left: calc((100% - ${(props) => props.canvasSize}px) / 2);
  top: calc((100vh - ${(props) => props.canvasSize}px) / 2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &.active {
    opacity: 1;
  }
  canvas {
    position: absolute;
    z-index: 10;
    width: ${(props) => props.canvasSize}px;
    height: ${(props) => props.canvasSize}px;
  }
`

const Title = styled.header`
  position: absolute;
  top: 20px;
  left: 20px;
  @media only screen and (min-width: 540px) {
    top: 2vw;
    left: 2vw;
  }
  margin: 0;
  padding: 0;
`

const Footer = styled.footer`
  position: absolute;
  bottom: 20px;
  right: 20px;
  left: 20px;
  @media only screen and (min-width: 540px) {
    bottom: 2vw;
    left: 2vw;
    right: 2vw;
  }
  display: flex;
  justify-content: space-between;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 15px;
    list-style-type: none;
  }
`

const Image = styled.img<{ canvasSize: number }>`
  width: ${(props) => props.canvasSize}px;
  height: ${(props) => props.canvasSize}px;
  position: absolute;
  z-index: 5;
`

const P = styled.p`
  font-size: 12px;
`

export default App
