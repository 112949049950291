import styled from "styled-components"

interface ControlsProps {
  children: Array<React.ReactElement>
}

const Controls = ({ children }: ControlsProps) => <Wrapper>{children}</Wrapper>

const Wrapper = styled.div`
  position: absolute;
  z-index: 9999;
  top: 0px;
  right: 0px;
  max-width: 300px;
  span {
    display: inline-block;
    width: 30px;
    text-align: right;
  }
  a {
    display: inline-block;
    margin: 0px 5px;
    padding: 5px;
    color: white;
    text-decoration: none;
    transition: transform 0.15s ease-in-out;
    &:hover {
      transform: scale(1.5);
    }
    &:first-of-type {
      margin-left: -5px;
    }
  }
`

interface ControlProps {
  label: string
  value: number
  setter: any
  min?: number
  max?: number
  step?: number
}

export const Control = (props: ControlProps) => {
  const { setter, label, ...attrs } = props
  return (
    <div>
      <Label>{label}</Label>
      <input type="range" {...attrs} onChange={(e) => setter(parseInt(e.target.value))} />
      <span>{attrs.value}</span>
    </div>
  )
}

const Label = styled.label`
  display: inline-block;
  width: 100px;
  font-size: 12px;
`

export default Controls
